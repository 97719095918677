import {GridToolbarContainer} from "@mui/x-data-grid-pro";
import {useEffect, useState} from "react";
import {Table, TableCell, TableHead, TableRow, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {fetchViaPost} from "../helpers/fetchViaPost";

const css = `
  .wfa {
    width:-webkit-fill-available !important;
  }`
export const LeagueStandingsToolbar = ({
  standings,
                                         league,
                                         mode,
                                         handleModeChange,
                                       }) => {
    var goalAverage, homeAdvantage, drawPercentage = 0;

    var homeGoalsFor = 0
    var homeGoalsAgainst = 0
    var totalDraws = 0
    var totalGames = 0
    standings.forEach(function (standing, index) {
      homeGoalsFor += standing.home.goals_scored;
      homeGoalsAgainst += standing.home.goals_against;
      totalDraws += standing.home.draw;
      totalGames += standing.home.games_played;
    });
    var totalGoals = homeGoalsFor + homeGoalsAgainst;
    goalAverage = totalGoals / totalGames
    homeAdvantage = homeGoalsFor / (totalGoals / 2)
    drawPercentage = totalDraws / totalGames

    return (
      <GridToolbarContainer>
        <ToggleButtonGroup
          color="primary"
          sx={{flex: 1}}
          value={mode}
          exclusive
          onChange={handleModeChange}
        >
          <ToggleButton
            value="overall"
          >
            Total
          </ToggleButton>
          <ToggleButton
            value="home"
          >
            Home
          </ToggleButton>
          <ToggleButton
            value="away"
          >
            Away
          </ToggleButton>
          <style type="text/css">{css}</style>
          <ToggleButton value="" className="wfa">
            <Table>
              <TableRow>
                <TableCell align="center" sx={{borderBottom: 0, color: 'black'}}><strong>Goal
                  average:</strong> {Number(goalAverage).toFixed(3)}</TableCell>
                <TableCell align="center" sx={{
                  borderBottom: 0,
                  color: 'black'
                }}><strong>Home-factor:</strong> {Number(homeAdvantage).toFixed(3)}</TableCell>
                <TableCell align="center" sx={{
                  borderBottom: 0,
                  color: 'black'
                }}><strong>Drawpercentage:</strong> {Number(drawPercentage*100).toFixed(2)}%</TableCell>
              </TableRow>
            </Table>
          </ToggleButton>
        </ToggleButtonGroup>
      </GridToolbarContainer>
    );
  }
;
