import {
  Button,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import logo from "../images/transparent-logo.png";
import { fetchViaPost } from "../helpers/fetchViaPost";
import { useState } from "react";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://betty.works/">
        betty
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export function SignUp({ setAuth }) {
  const [loading, setLoading] = useState(false);

  async function sendToHubSpot({ email, firstName, lastName }, agreeMarketing) {
    const HUBSPOT_ID = "26201524";
    const FORM_ID = "c196d9b6-f371-4edb-b461-811cc78577c2";

    var data = {
      fields: [
        {
          objectTypeId: "0-1",
          name: "email",
          value: email,
        },
        {
          objectTypeId: "0-1",
          name: "firstname",
          value: firstName,
        },
        {
          objectTypeId: "0-1",
          name: "lastname",
          value: lastName,
        },
      ],
      legalConsentOptions: {
        // Include this object when GDPR options are enabled
        consent: {
          consentToProcess: true,
          text: "I agree to allow betty to store and process my personal data.",
          communications: [
            {
              value: agreeMarketing,
              subscriptionTypeId: 150982027,
              text: "I agree to receive marketing communications from betty.",
            },
          ],
        },
      },
    };

    try {
      await fetch(
        `https://api.hsforms.com/submissions/v3/integration/submit/${HUBSPOT_ID}/${FORM_ID}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify(data),
        }
      );
    } catch {}
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);

    const user = {
      email: data.get("email"),
      firstName: data.get("firstName"),
      lastName: data.get("lastName"),
    };

    await sendToHubSpot(user, data.get("agreeMarketing") === "on");

    const response = await (
      await fetchViaPost(
        "sign-up",
        JSON.stringify({ ...user, password: data.get("password") })
      )
    ).json();

    if (response.ok) window.location.pathname = response.token;
    setLoading(false);
  };

  return loading ? (
    <Box
      style={{
        display: "flex",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={logo} alt="betty" style={{ width: "10em" }} />

        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="given-name"
                name="firstName"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={({ target }) =>
                  (target.value = target.value.toLowerCase())
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                required
                control={
                  <Checkbox
                    name="agreeData"
                    label="agreeData"
                    id="agreeData"
                    required
                  />
                }
                label="I agree to allow betty to store and process my personal data (required)"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="agreeMarketing"
                    label="agreeMarketing"
                    id="agreeMarketing"
                  />
                }
                label="I agree to receive marketing communications from betty (optional)"
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </Button>
          <Grid container justifyContent="center" alignItems="center">
            <Button
              onClick={() => {
                setAuth("sign-in");
                window.location.pathname = "sign-in";
              }}
            >
              {"Already have an account? Sign in"}
            </Button>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </Container>
  );
}
