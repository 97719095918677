import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LeagueDrawer } from "./LeagueDrawer";
import { Auth } from "./Auth";
import { ConfirmProvider } from "material-ui-confirm";
import { Help } from "./Help";

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          '*::-webkit-scrollbar': {
            width: '0.4em'
          },
          '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,0.22)',
            outline: '1px solid slategrey'
          }
        },
      },
    },
  },
  palette: {
    primary: {
      main: "rgb(123,60,89)",
      light: "rgba(123,60,89,0.75)",
    },
    secondary: {
      main: "rgb(213,57,57)",
      light: "rgba(213,57,57,0.75)",
    },
    background: {
      default: "rgb(255,181,99)",
      pale: "rgb(255,181,99,0.2)",
    },
  },
});

if (
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1"
)
  window.document.title = window.location.host;

export function App() {
  const path = window.location.pathname.slice(1);
  let url = new URL(window.location.href);
  var c = url.searchParams.get("date");
  if (c!==null) {
    try {
      //console.log("DATE:", c)
      var newDay=c.substring(0,2);
      var newMon=c.substring(2,4);
      var newYear=c.substring(4,8);

      const startDate = new Date(newMon+"/"+newDay+"/"+newYear);
      startDate.setHours(23);
      sessionStorage.setItem("startDate", startDate.toISOString());
    }catch (e) {
      //
    }
  }

  if (!path.length || path === "sign-in" || path === "sign-up")
    return <Auth method={path} />;
  return (
    <ThemeProvider theme={theme}>
      <ConfirmProvider>
        {path === "help" ? <Help /> : <LeagueDrawer />}
      </ConfirmProvider>
    </ThemeProvider>
  );
}
