export function computeInterdependentValues(newRow, oldRow) {
  if (newRow.total_strength !== oldRow.total_strength) {
    const against_total = +oldRow.total_goals / (+newRow.total_strength + 1);
    const for_total = +oldRow.total_goals - against_total;
    const for_home = for_total * +oldRow.for_homefactor;
    const against_home = against_total * +oldRow.against_homefactor;
    const for_away = for_total * (2 - +oldRow.for_homefactor);
    const against_away = against_total * (2 - +oldRow.against_homefactor);

    return {
      ...newRow,
      against_total,
      for_total,
      for_home,
      against_home,
      for_away,
      against_away,
    };
  }

  if (newRow.total_goals !== oldRow.total_goals) {
    const against_total = +newRow.total_goals / (+oldRow.total_strength + 1);
    const for_total = +newRow.total_goals - against_total;
    const for_home = for_total * +oldRow.for_homefactor;
    const against_home = against_total * +oldRow.against_homefactor;
    const for_away = for_total * (2 - +oldRow.for_homefactor);
    const against_away = against_total * (2 - +oldRow.against_homefactor);

    return {
      ...newRow,
      against_total,
      for_total,
      for_home,
      against_home,
      for_away,
      against_away,
    };
  }
  if (newRow.for_total !== oldRow.for_total) {
    const total_strength = +newRow.for_total / +oldRow.against_total;
    const total_goals = +newRow.for_total + +oldRow.against_total;
    const for_home =
      (+oldRow.for_home * +newRow.for_total) /
      ((+oldRow.for_home + +oldRow.for_away) / 2);
    const for_away =
      (+oldRow.for_away * +newRow.for_total) /
      ((+oldRow.for_home + +oldRow.for_away) / 2);

    return {
      ...newRow,
      total_strength,
      total_goals,
      for_home,
      for_away,
    };
  }
  if (newRow.against_total !== oldRow.against_total) {
    const total_strength = +oldRow.for_total / +newRow.against_total;
    const total_goals = +oldRow.for_total + +newRow.against_total;
    const against_home =
      (+oldRow.against_home * +newRow.against_total) /
      ((+oldRow.against_home + +oldRow.against_away) / 2);
    const against_away =
      (+oldRow.against_away * +newRow.against_total) /
      ((+oldRow.against_home + +oldRow.against_away) / 2);

    return {
      ...newRow,
      total_strength,
      total_goals,
      against_home,
      against_away,
    };
  }
  if (newRow.for_home !== oldRow.for_home) {
    const for_total =
      +oldRow.for_total + (newRow.for_home - oldRow.for_home) / 2;
    const for_homefactor =
      +newRow.for_home / ((+newRow.for_home + +oldRow.for_away) / 2);
    const total_goals = for_total + +oldRow.against_total;
    const total_strength = for_total / +oldRow.against_total;

    return {
      ...newRow,
      for_total,
      for_homefactor,
      total_goals,
      total_strength,
    };
  }

  if (newRow.against_home !== oldRow.against_home) {
    const against_total =
      +oldRow.against_total + (newRow.against_home - oldRow.against_home) / 2;
    const against_homefactor =
      newRow.against_home / ((newRow.against_home + oldRow.against_away) / 2);
    const total_goals = against_total + +oldRow.for_total;
    const total_strength = +oldRow.for_total / against_total;

    return {
      ...newRow,
      against_total,
      against_homefactor,
      total_goals,
      total_strength,
    };
  }
  if (newRow.for_away !== oldRow.for_away) {
    const for_total =
      +oldRow.for_total + (newRow.for_away - oldRow.for_away) / 2;
    const for_homefactor =
      +oldRow.for_home / ((+oldRow.for_home + +newRow.for_away) / 2);
    const total_goals = +oldRow.against_total + for_total;
    const total_strength = for_total / +oldRow.against_total;

    return {
      ...newRow,
      for_total,
      for_homefactor,
      total_goals,
      total_strength,
    };
  }
  if (newRow.against_away !== oldRow.against_away) {
    const against_total =
      +oldRow.against_total + (+newRow.against_away - +oldRow.against_away) / 2;
    const against_homefactor =
      +oldRow.against_home /
      ((+oldRow.against_home + +newRow.against_away) / 2);
    const total_goals = +oldRow.for_total + against_total;
    const total_strength = +oldRow.for_total / against_total;

    return {
      ...newRow,
      against_total,
      against_homefactor,
      total_goals,
      total_strength,
    };
  }

  if (newRow.for_homefactor !== oldRow.for_homefactor) {
    const for_home = +newRow.for_homefactor * +oldRow.for_total;
    const for_away = +oldRow.for_total * 2 - for_home;

    return {
      ...newRow,
      for_home,
      for_away,
    };
  }
  if (newRow.against_homefactor !== oldRow.against_homefactor) {
    const against_home = +newRow.against_homefactor * +oldRow.against_total;
    const against_away = +oldRow.against_total * 2 - against_home;

    return {
      ...newRow,
      against_home,
      against_away,
    };
  }
  return newRow;
}
