import {forwardRef, useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {CircularProgress, Container, Table, TableCell, TableHead, TableRow} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import {fetchFromApi} from "../helpers/fetchFromApi";
import Slide from "@mui/material/Slide";
import {AppBar} from "../styled-components/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {LeagueStandingsToolbar} from "./LeagueStandingsToolbar";
import {extractFlag} from "./Help";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function LeagueStandingsDialog({league, setOpen}) {
  function handleClose() {
    setOpen(false);
  }

  const [standings, setStandings] = useState([]);
  const [rows, setRows] = useState([]);
  const [mode, setMode] = useState('overall');

  useEffect(() => {
    if (!league) {
      return;
    }

    async function fetchSeasonTeams() {
      setRows([]);
      const teamData = await fetchTeams();

      const response = await (
        await fetchFromApi(
          `fetch-data/standings/season/${league.current_season_id}`
        )
      ).json();

      const newRows = [];
      for (const team of response?.data[0]?.standings?.data) {
        const path = teamData.find(
          (teamTwo) => teamTwo.id === team.team_id
        ).logo_path;
        newRows.push({
          ...team,
          away: {
            draw: team.overall?.draw - team.home?.draw,
            games_played: team.overall?.games_played - team.home?.games_played,
            goals_against: team.overall?.goals_against - team.home?.goals_against,
            goals_scored: team.overall?.goals_scored - team.home?.goals_scored,
            lost: team.overall?.lost - team.home?.lost,
            points: team.overall?.points - team.home?.points,
            won: team.overall?.won - team.home?.won,
          },
          logo_path: path,
        });
      }

      calculateTable(newRows, mode);

      const standingsResponse = await (
        await fetchFromApi(`fetch-data/standings/season/${league.current_season_id}`)
      ).json();
      setStandings(standingsResponse.data[0].standings.data);
    }

    async function fetchTeams() {
      const response = await (
        await fetchFromApi(
          `fetch-data/teams/season/${league.current_season_id}`
        )
      ).json();
      return response.data;
    }

    fetchSeasonTeams();
  }, [league]);


  const calculateTable = (rows, mode) => {
    if (!rows.length) {
      return;
    }
    rows.every(team => team.tableMode = team[mode]);
    const newRows = rows.slice().sort((teamOne, teamTwo) => {
      if (teamOne.tableMode.points === teamTwo.tableMode.points) {
        // now sort by points, goal diff, then raw goals
        const goalDiffOne = teamOne.tableMode.goals_scored - teamOne.tableMode.goals_against;
        const goalDiffTwo = teamTwo.tableMode.goals_scored - teamTwo.tableMode.goals_against;
        if (goalDiffOne === goalDiffTwo) {
          // raw goals
          if (teamOne.tableMode.goals_scored === teamTwo.tableMode.goals_scored) {
            return 0;
          }

          return teamOne.tableMode.goals_scored > teamTwo.tableMode.goals_scored ? -1 : 1
        }

        return goalDiffOne > goalDiffTwo ? -1 : 1
      }
      return teamOne.tableMode.points > teamTwo.tableMode.points ? -1 : 1
    })

    let newPosition = 1;
    newRows.every((team) => {
      team.position = newPosition;
      newPosition++;

      return team;
    });

    setRows(newRows);
  }

  const handleModeChange = (event, newMode) => {
    if (newMode.length>0) {
      setMode(newMode);
      calculateTable(rows, newMode);
    }
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={league}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{position: "relative"}}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon/>
            </IconButton>
            <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5em",
                }}
              >
                <img
                  style={{width: "1em"}}
                  alt={league?.country?.data?.name}
                  title={league?.country?.data?.name}
                  src={`data:image/svg+xml;utf8,${encodeURIComponent(
                    extractFlag(league?.country?.data?.extra)
                  )}`}
                />
                {league?.name} Standings
              </div>
            </Typography>
          </Toolbar>
        </AppBar>
        <Container maxWidth="xxl">
          {!rows.length ? (
            <CircularProgress/>
          ) : (
            <DataGridPro
              getRowId={(row) => row.team_id}
              disableColumnMenu
              disableColumnReorder
              hideFooter
              rows={rows}
              columns={columns}
              sx={{height: "90vh"}}
              components={{
                Toolbar: () => (
                  <LeagueStandingsToolbar
                    standings={standings}
                    league={league}
                    mode={mode}
                    handleModeChange={handleModeChange}
                  />
                )
              }}
            />
          )}
        </Container>
      </Dialog>
    </div>
  );
}
const columns = [
  {
    field: "position",
    headerName: "Platzierung",
    type: "number",
    sortable: false,
    width: 50,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "team_name",
    headerName: "Team",
    sortable: false,
    flex: 1,
    renderCell: ({value, row}) => (
      <div style={{display: "flex", alignItems: "center", gap: "0.5em"}}>
        <img src={row.logo_path} style={{height: "1.5em"}} alt=""/>
        {row.team_name}
      </div>
    ),
  },
  {
    field: "tableMode.games_played",
    headerName: "Spiele",
    type: "number",
    sortable: false,
    flex: 1,
    align: "right",
    headerAlign: "right",
    valueGetter: (params) => params.row.tableMode.games_played,
  },
  {
    field: "tableMode.won",
    headerName: "S",
    type: "number",
    sortable: false,
    flex: 1,
    align: "right",
    headerAlign: "right",
    valueGetter: (params) => params.row.tableMode.won,
  },
  {
    field: "tableMode.draw",
    headerName: "U",
    type: "number",
    sortable: false,
    flex: 1,
    align: "right",
    headerAlign: "right",
    valueGetter: (params) => params.row.tableMode.draw,
  },
  {
    field: "tableMode.lost",
    headerName: "N",
    type: "number",
    sortable: false,
    flex: 1,
    align: "right",
    headerAlign: "right",
    valueGetter: (params) => params.row.tableMode.lost,
  },
  {
    field: "tableMode.goals",
    headerName: "Tore",
    type: "number",
    sortable: false,
    flex: 1,
    align: "right",
    headerAlign: "right",
    valueGetter: (params) =>
      params.row.tableMode.goals_scored + "-" + params.row.tableMode.goals_against,
  },
  {
    field: "tableMode.goals_difference",
    headerName: "Diff.",
    type: "number",
    sortable: false,
    flex: 1,
    align: "right",
    headerAlign: "right",
    valueGetter: (params) =>
      (params.row.tableMode.goals_scored - params.row.tableMode.goals_against > 0
        ? "+"
        : "") +
      (params.row.tableMode.goals_scored - params.row.tableMode.goals_against),
  },
  {
    field: "tableMode.points",
    headerName: "Punkte",
    type: "number",
    sortable: false,
    flex: 1,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => params.row.tableMode.points,
    renderCell: ({value}) => <strong>{value}</strong>,
  },
];
