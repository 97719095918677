import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

export default function BasicTable(rows) {
  function getMap() {
    const output = [];
    for (let i = 0; i < rows["headers"]?.length; i++) {
      output.push(<TableRow style={{ background: '#f4f4f4' }}>
        <TableCell align="center" style={{fontWeight: 'bold'}}>{rows["headers"][i].title}</TableCell>
        <TableCell align="center" style={{fontWeight: 'bold'}}>{rows["headers"][i].first}</TableCell>
        <TableCell align="center" style={{fontWeight: 'bold'}}>{rows["headers"][i].second}</TableCell>
      </TableRow>);
      {output.push(rows["rows"][i]?.map((row) => (
        <TableRow
          key={row.name}
          sx={{'&:last-child td, &:last-child th': {border: 0}}}
        >
          <TableCell component="th" scope="row" align="center" style={{padding: '3px 10px'}}>{row.name}</TableCell>
          <TableCell align="center" style={{padding: '3px 10px'}}>{row.first}</TableCell>
          <TableCell align="center" style={{padding: '3px 10px'}}>{row.second}</TableCell>
        </TableRow>
      )))}
    }
    return <TableBody>{output}</TableBody>;
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{minWidth: 650}} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={3} style={{ background: '#ccc' }}>{rows["title"]}</TableCell>
          </TableRow>
        </TableHead>
        {getMap()}
      </Table>
    </TableContainer>
  );
}
