const HOST =
  process.env.NODE_ENV === "development" &&
  process.env.REACT_APP_API_ENV !== "production"
    ? "http://localhost:8787/"
    : "https://api.betty.works/";

export async function fetchFromApi(path, options = { headers: {} }) {
  const response = await fetch(HOST + path, {
    ...options,
    headers: {
      ...options.headers,
      Authorization: `Bearer ${window.location.pathname.slice(1)}`,
    },
  });
  // const json = await raw.json()
  return response;
}
