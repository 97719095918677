import { useEffect, useState } from "react";
import { SignIn } from "./SignIn";
import { SignUp } from "./SignUp";

export function Auth({ method }) {
  const [auth, setAuth] = useState(method || "sign-in");

  useEffect(() => {
    if (window.location.pathname === "/") window.location.pathname = "sign-in";
  }, []);

  return auth === "sign-in" ? (
    <SignIn setAuth={setAuth} />
  ) : (
    <SignUp setAuth={setAuth} />
  );
}
