import {
  CssBaseline,
  Link,
  Typography,
  Container,
  Paper,
  List,
  ListItem,
} from "@mui/material";

export function extractFlag(extra) {
  let flag = extra?.flag;
  if (typeof flag === "undefined" ){
    // https://de.m.wikipedia.org/wiki/Datei:World_Flag_%282004%29.svg
    return "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n" +
      "<svg width=\"104.97mm\" height=\"63.663mm\" version=\"1.1\" viewBox=\"0 0 104.97 63.663\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
      "<g transform=\"translate(-18.404 -69.01)\">\n" +
      "<rect x=\"19.588\" y=\"70.676\" width=\"102.6\" height=\"60.192\" fill=\"none\" stroke=\"#000\" stroke-linejoin=\"round\" stroke-width=\"2.5581\" style=\"paint-order:stroke markers fill\"/>\n" +
      "</g>\n" +
      "</svg>\n"
  }
  return flag;
}

export function Help() {
  return (
    <>
      <CssBaseline />
      <Container component="main" maxWidth="xl" style={{ minHeight: "100vh" }}>
        <Paper style={{ margin: "1em", padding: "1em", minHeight: "100vh" }}>
          <Typography component="h1" variant="h2" sx={{ py: ".5em" }}>
            Help
          </Typography>
          <List>
            <ListItem>
              <Link href="#videos">Videos</Link>
            </ListItem>
            <ListItem>
              <Link href="#blog-posts">Blog posts</Link>
            </ListItem>
          </List>
          <Typography
            component="h2"
            variant="h3"
            sx={{ py: ".5em" }}
            id="videos"
          >
            Videos
          </Typography>
          <iframe
            width={560 * 2}
            height={315 * 2}
            src="https://www.youtube-nocookie.com/embed/keUq2_imtvs"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <Typography
            component="h2"
            variant="h3"
            sx={{ py: ".5em" }}
            id="blog-posts"
          >
            Blog posts
          </Typography>
          <List>
            <ListItem>
              <Link
                href="https://blog.betty.works/posts/1-introduction-to-sports-betting"
                target="_blank"
              >
                Introduction to sports betting
              </Link>
            </ListItem>
          </List>
        </Paper>
      </Container>
    </>
  );
}
