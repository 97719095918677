import {STATI} from "./stati";

export function canEvaluate(row) {
  let status = row.status;
  return (
    status === STATI.compiled ||
    status === STATI["compilation confirmed"] ||
    status === STATI["compilation confirmed"] ||
    status === STATI["awaiting evaluation"]
  );
}
