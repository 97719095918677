import { fetchFromApi } from "./fetchFromApi.js";
export async function fetchViaPost(path, body, options = { headers: {} }) {
  return fetchFromApi(path, {
    ...options,
    method: "POST",
    headers: {
      ...options.headers,
      "Content-Type": "application/json",
    },
    body,
  });
}
