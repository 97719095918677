import { GridToolbarContainer } from "@mui/x-data-grid-pro";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { PublishedWithChanges as PublishedWithChangesIcon } from "@mui/icons-material";

export const FixturesToolbar = ({
  mode,
  handleModeChange,
  forceCompile,
  setSelectionModel,
  setForceCompile,
}) => {
  return (
    <GridToolbarContainer>
      <ToggleButtonGroup
        color="primary"
        value={mode}
        exclusive
        onChange={handleModeChange}
      >
        <ToggleButton
          value="compile"
          title="Compilation mode allows you to compile the selected matches"
        >
          compilation mode
        </ToggleButton>
        <ToggleButton
          value="evaluate"
          title="Evaluation mode allows you to evaluate the selected matches"
        >
          evaluation mode
        </ToggleButton>
      </ToggleButtonGroup>
      {mode === "compile" && (
        <ToggleButton
          value="check"
          selected={forceCompile}
          onChange={() => {
            setSelectionModel([]);
            setForceCompile(!forceCompile);
          }}
        >
          <PublishedWithChangesIcon titleAccess="enable compilation, even if match is already kicked off or finished; be careful with that because it makes your data partly inconsitent" />
        </ToggleButton>
      )}
    </GridToolbarContainer>
  );
};
