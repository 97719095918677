import { StepEditField } from "../components/StepEditField";

export const teamStrengthColumns = [
  {
    field: "rank",
    headerName: "rank",
    type: "number",
    editable: false,
    width: 50,
    align: "center",
    headerAlign: "center",
  },

  { field: "custom_name", headerName: "Team", flex: 1, editable: false, minWidth:150 },

  {
    field: "total_strength",
    headerName: "Strength",
    type: "number",
    editable: true,
    flex: 1,
    renderCell: ({ value }) => <strong>{(+value).toFixed(3)}</strong>,
    renderEditCell: (params) => <StepEditField {...params} />,
    align: "center",
    headerAlign: "center",
    cellClassName: "total-strength",
  },
  {
    field: "total_goals",
    headerName: "Goal sum exp",
    type: "number",
    editable: true,
    flex: 1,
    renderCell: ({ value }) => value?.toFixed?.(3),
    renderEditCell: (params) => <StepEditField {...params} />,
    cellClassName: "total-goals",
    align: "center",
    headerAlign: "center",
  },
  {
    field: "for_total",
    headerName: "for total",
    type: "number",
    editable: true,
    flex: 1,
    renderCell: ({ value }) => value?.toFixed?.(3),
    renderEditCell: (params) => <StepEditField {...params} />,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "against_total",
    headerName: "against total",
    type: "number",
    editable: true,
    flex: 1,
    renderCell: ({ value }) => value?.toFixed?.(3),
    renderEditCell: (params) => <StepEditField {...params} />,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "for_home",
    headerName: "for home",
    type: "number",
    editable: true,
    flex: 1,
    renderCell: ({ value }) => value?.toFixed?.(3),
    renderEditCell: (params) => <StepEditField {...params} />,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "against_home",
    headerName: "against home",
    type: "number",
    editable: true,
    flex: 1,
    renderCell: ({ value }) => value?.toFixed?.(3),
    renderEditCell: (params) => <StepEditField {...params} />,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "for_away",
    headerName: "for away",
    type: "number",
    editable: true,
    flex: 1,
    renderCell: ({ value }) => value?.toFixed?.(3),
    renderEditCell: (params) => <StepEditField {...params} />,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "against_away",
    headerName: "against away",
    type: "number",
    editable: true,
    flex: 1,
    renderCell: ({ value }) => value?.toFixed?.(3),
    renderEditCell: (params) => <StepEditField {...params} />,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "for_homefactor",
    headerName: "for homefactor",
    type: "number",
    editable: true,
    flex: 1,
    renderCell: ({ value }) => value?.toFixed?.(3),
    renderEditCell: (params) => <StepEditField {...params} />,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "against_homefactor",
    headerName: "against homefactor",
    type: "number",
    editable: true,
    flex: 1,
    renderCell: ({ value }) => value?.toFixed?.(3),
    renderEditCell: (params) => <StepEditField {...params} />,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "draw_factor",
    headerName: "draw factor",
    type: "number",
    editable: true,
    flex: 1,
    renderCell: ({ value }) => value?.toFixed?.(3),
    renderEditCell: (params) => <StepEditField {...params} />,
    align: "center",
    headerAlign: "center",
  },
];
