import {
  Alert,
  Button,
  CircularProgress,
  Container,
  Dialog,
  IconButton,
  Slide,
  Snackbar,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {fetchViaPost} from "../helpers/fetchViaPost";
import {forwardRef, useEffect, useRef, useState} from "react";
import {AppBar} from "../styled-components/AppBar";
import {TeamStrengthEditor} from "./TeamStrengthEditor";
import {fixInconsistencies} from "../helpers/fixInconsistencies";
import {checkForInconsistencies} from "../helpers/checkForInconsistencies";
import {ConfirmationDialog} from "./ConfirmationDialog";
import {useConfirm} from "material-ui-confirm";
import {extractFlag} from "./Help";
import {ConfirmationDialogRaw} from "./ConfirmationDialogRaw";

const CustomAlert = forwardRef(function CustomAlert(props, ref) {
  return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function TeamStrengthDialog({league, setOpen, user}) {
  const promptToSaveChanges = useConfirm();
  const handleUnsavedChanges = async () => {
    try {
      await promptToSaveChanges({
        title: `Unsaved changes detected`,
        description: "Would you like to save them?",
      });
      await saveUnsavedChanges();
    } catch {
      discardUnsavedChanges();
    }
  };

  const apiRef = useRef(null);
  useState(false);

  function handleClose() {
    setErrorMessage("");
    if (!hasChanges) setOpen(false);
    else handleUnsavedChanges();
  }

  async function setDefaultValues() {
    let jsonKey = JSON.stringify({
      season_id: league.current_season_id,
      league_id: league.id
    })

    const resp = await (
      await fetchViaPost(
        "create-season-default",
        JSON.stringify({
          season_id: league.current_season_id,
          league_id: league.id,
          league_name: league.name,
        })
      )).json();
    if (resp) {
      setSeasonInitNeeded(false)
      setInconsistenciesFound(false);
      //handleClose();
      fetchTeams();
    } else {
      setErrorMessage("Last season cannot be used! Please try another initialization type!");
    }
  }

  async function duplicateValuesFromCreatedSeason() {
    let jsonKey = JSON.stringify({
      season_id: league.current_season_id,
      league_id: league.id
    })

    const resp = await (
      await fetchViaPost(
        "duplicate-from-created",
        JSON.stringify({
          season_id: league.current_season_id,
          league_id: league.id,
          league_name: league.name,
        })
      )).json();
    console.log("RESP", resp);
    if (resp) {
      console.log("RESP1", resp);
      setSeasonInitNeeded(false)
      setInconsistenciesFound(false);
      handleClose();
      fetchTeams();
    } else {
      console.log("RESP2", resp);
      setErrorMessage("Season is not initialized! Please contact \"wettopa\"!");
    }
  }

  async function duplicateValuesFromPreviousSeason() {
    // TODO: hinweis für Benutzer zeigen, dass Season muss initialisiert werden

    try {
      const teams = await (
        await fetchViaPost(
          "duplicate-season-as-previous",
          JSON.stringify({
            season_id: league.current_season_id,
            league_id: league.id
          })
        )).json();
      setSeasonInitNeeded(false)
      setInconsistenciesFound(false);
      handleClose();
      fetchTeams()
    } catch (e) {
      setErrorMessage("Last season cannot be used! Please try another initialization type!");
      //console.log(e)
    }

  }

  async function saveUnsavedChanges() {
    await handleSave();
    setOpen(false);
    setHasChanges(false);
  }

  function discardUnsavedChanges() {
    setOpen(false);
    setHasChanges(false);
  }

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  async function handleSave() {
    const strengths = rows.map((e) => {
      const {rank, total_goals, custom_name, ...row} = e;
      return row;
    });
    await fetchViaPost("put-team-strengths", JSON.stringify(strengths));
    setSnackbarOpen(true);
    setHasChanges(false);
  }

  async function fetchTeams() {
    setTeams([]);
    try {
      const teams = await (
        await fetchViaPost(
          "get-team-strengths-by-season-id",
          JSON.stringify({
            season_id: league.current_season_id,
            league_id: league.id
          })
        )
      ).json();
      const values = Object.values(teams);
      //TODO: another check of existing Season_parameters should be done and explicitly check if setSeasonInitNeeded is false
      setSeasonInitNeeded(Object.keys(teams).length === 0 && teams.constructor === Object)
      setTeams(values);
      if (!checkForInconsistencies(values)) setInconsistenciesFound(true);
    } catch (ex) {
      setSeasonInitNeeded(true)
    }
  }

  const [teams, setTeams] = useState([]);
  const [rows, setRows] = useState([]);
  const [inconsistenciesFound, setInconsistenciesFound] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [closeParent, setCloseParent] = useState(false);

  const [seasonInitNeeded, setSeasonInitNeeded] = useState(false);
  const [newValue, setNewValue] = useState(false);

  useEffect(() => {
    if (!league) return;

    fetchTeams();
  }, [league]);

  return (
    <div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <CustomAlert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{width: "100%"}}
        >
          Your changes have been saved.
        </CustomAlert>
      </Snackbar>
      <ConfirmationDialogRaw
        user={{user}}
        errorMessage={errorMessage}
        open={seasonInitNeeded}
        onCancel={(value) => {
          setInconsistenciesFound(false);
          setSeasonInitNeeded(false);
          handleClose();
        }}
        onConfirm={(value) => {
          if (typeof value === "string" && value === 'Take neutral values') {
            setDefaultValues()
          }
          if (typeof value === "string" && value === 'Take from last season') {
            duplicateValuesFromPreviousSeason();
          }
          if (typeof value === "string" && value === 'Take from "wettopa"') {
            duplicateValuesFromCreatedSeason();
          }
          if (typeof value === "string" && value === 'Take "earned" values') {
            setInconsistenciesFound(false);
            setSeasonInitNeeded(false);
            handleClose();
          }
        }}
      />
      <ConfirmationDialog
        open={inconsistenciesFound}
        onAbort={() => {
          setInconsistenciesFound(false);
        }}
        onConfirm={() => {
          setTeams(fixInconsistencies(teams));
          setInconsistenciesFound(false);
        }}
        title="Inconsistencies found"
        description="Would you like to fix them (recommended)?"
      />
      <Dialog
        fullScreen
        open={league}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{position: "relative"}}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon/>
            </IconButton>
            <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5em",
                }}
              >
                <img
                  style={{width: "1em"}}
                  alt={league?.country?.data?.name}
                  title={league?.country?.data?.name}
                  src={`data:image/svg+xml;utf8,${encodeURIComponent(
                    extractFlag(league?.country?.data?.extra)
                  )}`}
                />
                {league?.name}
              </div>
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={handleSave}
              title="Saving your changes You can´t restore your former settings. You can still manually adjust again at any point."
            >
              save
            </Button>
          </Toolbar>
        </AppBar>
        <Container maxWidth="xxl">
          {!teams.length ? (
            <CircularProgress/>
          ) : (
            <TeamStrengthEditor
              user_id={user.id}
              season_id={league.current_season_id}
              teams={teams}
              rows={rows}
              setRows={setRows}
              apiRef={apiRef}
              setHasChanges={setHasChanges}
              setCloseParent={handleClose}
            />
          )}
        </Container>
      </Dialog>
    </div>
  );
}
