export function computeTeamStrengthMutation(newRow, oldRow) {
  if (newRow.total_strength !== oldRow.total_strength) {
    return `total strength from '${oldRow.total_strength}' to '${newRow.total_strength}'`;
  }
  if (newRow.total_goals !== oldRow.total_goals) {
    return `total goals from '${oldRow.total_goals}' to '${newRow.total_goals}'`;
  }
  if (newRow.for_total !== oldRow.for_total) {
    return `for total from '${oldRow.for_total}' to '${newRow.for_total}'`;
  }
  if (newRow.against_total !== oldRow.against_total) {
    return `against total from '${oldRow.against_total}' to '${newRow.against_total}'`;
  }
  if (newRow.for_home !== oldRow.for_home) {
    return `for home from '${oldRow.for_home}' to '${newRow.for_home}'`;
  }
  if (newRow.against_home !== oldRow.against_home) {
    return `against home from '${oldRow.against_home}' to '${newRow.against_home}'`;
  }
  if (newRow.for_away !== oldRow.for_away) {
    return `for away from '${oldRow.for_away}' to '${newRow.for_away}'`;
  }
  if (newRow.against_away !== oldRow.against_away) {
    return `against away from '${oldRow.against_away}' to '${newRow.against_away}'`;
  }
  if (newRow.for_homefactor !== oldRow.for_homefactor) {
    return `for homefactor from '${oldRow.for_homefactor}' to '${newRow.for_homefactor}'`;
  }
  if (newRow.against_homefactor !== oldRow.against_homefactor) {
    return `against homefactor from '${oldRow.against_homefactor}' to '${newRow.against_homefactor}'`;
  }
  if (newRow.draw_factor !== oldRow.draw_factor) {
    return `drawfactor from '${oldRow.draw_factor}' to '${newRow.draw_factor}'`;
  }
  return null;
}
