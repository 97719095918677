import {DataGridPro} from "@mui/x-data-grid-pro";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Snackbar,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {useCallback, useEffect, useRef, useState} from "react";
import {teamStrengthColumns} from "../parts/teamStrengthColumns";
import {computeTeamStrengthMutation} from "../helpers/computeTeamStrengthMutation";
import {computeInterdependentValues} from "../helpers/computeInterdependentValues";
import {sortAndRankTeams} from "../helpers/sortAndRankTeams";
import {fetchViaPost} from "../helpers/fetchViaPost";
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export function TeamStrengthEditor({
                                     user_id,
                                     season_id,
                                     teams,
                                     rows,
                                     setRows,
                                     apiRef,
                                     setHasChanges,
                                     setCloseParent,
                                   }) {
  useEffect(() => {
    setRows(sortAndRankTeams(teams));
  }, [teams, setRows]);

  const noButtonRef = useRef(null);
  const [panelHeaderValue, setPanelHeaderValue] = useState(null);
  const [promiseArguments, setPromiseArguments] = useState(null);

  const [seasonParams, setSeasonParams] = useState([]);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickDelete = async () => {
    const request = await fetchViaPost("delete-league", season_id);
    setOpen(false);
    setCloseParent(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        let response = await fetchViaPost("get-season-parameters", JSON.stringify({user_id, season_id}));
        setSeasonParams(JSON.parse(await response.json()));
      } catch (err) {
        //console.log(err);
      }
    }

    fetchData();
  }, []);

  const [snackbar, setSnackbar] = useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const processRowUpdate = useCallback(
    (newRow, oldRow) => {
      return new Promise((resolve, reject) => {
        const mutation = computeTeamStrengthMutation(newRow, oldRow);
        if (mutation) {
          setHasChanges(true);
          setPromiseArguments({ resolve, reject, newRow, oldRow });
        } else {
          resolve(oldRow);
        }
      });
    },
    [setHasChanges]
  );

  const panelHeader = () => {
    if (seasonParams !== [] && seasonParams !== null) {
      return (
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center" title="Goal average of this league of the games played so far."><strong>(Exp) Goal average:</strong> {Number(seasonParams.stored_goal_average).toFixed(3)}</TableCell>
              <TableCell align="center" title="Home factor, that has been achieved so far in this league for all the fixtures played till now. Formula: Home goals scored divided by half of the total goals scored."><strong>(Exp) Home-factor:</strong> {Number(seasonParams.exp_home_factor).toFixed(3)}</TableCell>
              <TableCell align="center"><strong>Drawfactor:</strong> {Number(seasonParams.drawfactor).toFixed(3)}</TableCell>
            </TableRow>
          </TableHead>
        </Table>
      )
    }
  }

  const handleNo = () => {
    const { oldRow, resolve } = promiseArguments;
    resolve(oldRow);
    setPromiseArguments(null);
  };

  const handleYes = async () => {
    const { newRow, oldRow, reject, resolve } = promiseArguments;

    try {
      const computedValues = computeInterdependentValues(newRow, oldRow);
      resolve(computedValues);
      setPromiseArguments(null);
      const updatedRow = { ...computedValues, isNew: false };
      setRows(
        rows.map((row) => (row.id === computedValues.id ? updatedRow : row))
      );
    } catch (error) {
      reject(oldRow);
      setPromiseArguments(null);
    }
  };

  useEffect(() => {
    if (promiseArguments && checked) {
      handleYes();
    }
  }, [promiseArguments, handleYes]);

  const handleCellEditCommit = () => {};

  const handleEntered = () => {};

  const renderConfirmDialog = () => {
    if (!promiseArguments) {
      return null;
    }

    const { newRow, oldRow } = promiseArguments;
    const mutation = computeTeamStrengthMutation(newRow, oldRow);

    return (
      <Dialog
        maxWidth="xs"
        TransitionProps={{ onEntered: handleEntered }}
        open={!!promiseArguments}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent dividers>
          {`Pressing 'Yes' will change ${mutation}. Keep in mind: the changes will not be stored to your data set before you select the "Save" button to the top right`}
          <FormControlLabel
            control={<Checkbox checked={checked} onChange={handleChange} />}
            label="Don't show again during this dialog"
          />
        </DialogContent>
        <DialogActions>
          <Button ref={noButtonRef} onClick={handleNo}>
            No
          </Button>
          <Button onClick={handleYes}>Yes</Button>
        </DialogActions>
      </Dialog>
    );
  };
  const theme = useTheme();

  return (
    <Box
      sx={{
        height: 300,
        width: "100%",
        "& .total-strength": {
          backgroundColor: theme.palette.primary.light,
          color: "white",
          fontWeight: "bold",
          fontSize: "1.5em",
        },
        "& .total-goals": {
          backgroundColor: theme.palette.secondary.light,
          color: "white",
          fontWeight: "600",
          fontSize: "1.5em",
        },
      }}
    >
      {renderConfirmDialog()}
      {panelHeader()}
      <DataGridPro
        onCellEditStop={handleCellEditCommit}
        rows={rows}
        columns={teamStrengthColumns}
        sx={{height: "90vh"}}
        processRowUpdate={processRowUpdate}
        experimentalFeatures={{newEditingApi: true}}
        ref={apiRef}
      />
      {!!snackbar && (
        <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000}>
          <Alert {...snackbar} onClose={handleCloseSnackbar}/>
        </Snackbar>
      )}
      <Button variant="outlined" onClick={handleClickOpen} startIcon={<DeleteIcon/>} color="error"
              style={{margin: '10px 10px'}}>
        Delete settings
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You will loose all your settings for this league
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleClickDelete} autoFocus>Yes</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
