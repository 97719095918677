import { useGridApiContext } from "@mui/x-data-grid";
import { TextField } from "@mui/material";

export function StepEditField(props) {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const handleValueChange = (event) => {
    const newValue = event.target.value;
    apiRef.current.setEditCellValue({
      id,
      field,
      value: newValue,
    });
  };

  return (
    <TextField
      onChange={handleValueChange}
      id="outlined-number"
      type="number"
      value={value}
      min="0"
      inputProps={{ step: "0.005" }}
    />
  );
}
