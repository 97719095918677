const epsilon = 0.05;

export function checkForInconsistencies(ranking) {
  let returnStatus = true;
  for (const rank of ranking) {
    if (
      Math.abs((rank.for_home + rank.for_away) / 2 - rank.for_total) > epsilon
    ) {
      returnStatus = false;
    }
    if (
      Math.abs(
        (rank.against_home + rank.against_away) / 2 - rank.against_total
      ) > epsilon
    ) {
      returnStatus = false;
    }
    if (
      Math.abs(rank.for_homefactor - rank.for_home / rank.for_total) > epsilon
    ) {
      returnStatus = false;
    }
    if (
      Math.abs(
        rank.against_homefactor - rank.against_home / rank.against_total
      ) > epsilon
    ) {
      returnStatus = false;
    }
  }
  return returnStatus;
}
