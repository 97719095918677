import {styled} from "@mui/material/styles";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import * as React from "react";

export const HtmlTooltip = styled(({className, ...props}) => (
  <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ffffff",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

/*
const StyledTooltip = ({ title, children, ...props }) => (
  <Tooltip
    {...props}
    title={title}
    placement="bottom"
    arrow
    componentsProps={{
      tooltip: {
        sx: {
          fontSize: '0.8em',
          color: "#000000",
          background: "#ffffff !important",
        },
      },
    }}
  >
    {children}
  </Tooltip>
);
*/
