import {Box, Container, Paper,} from "@mui/material";
import {useEffect, useState} from "react";
import {fetchViaPost} from "../helpers/fetchViaPost";

export function Export({token}) {
  const [exportResponse, setExportResponse] = useState("Loading...");

  useEffect(() => {
    async function fetchData() {
      try {
        let response = await fetchViaPost("get-user-export", JSON.stringify({token: token}));
        let data = await response.json()
        setExportResponse(data);
      } catch (err) {
      }
    }

    fetchData();
  }, []);

  return <Container maxWidth="xl" style={{height: "100%"}}>
    <Box
      sx={{
        display: "flex",
        height: "75vh",
        "& .row--expanded": {
          bgcolor: (theme) => "rgb(255,181,99,0.2)",
        },
      }}
      component={Paper}
    >
      <div style={{flexGrow: 1, alignContent: "center", overflowY: "scroll"}}>
        <pre>
        {JSON.stringify(exportResponse, null, 2)}
        </pre>
      </div>
    </Box>
  </Container>
}
