import * as React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export default function YoutubeModal({title, youtubeId}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  let tag = <>
    <div align="center">
      <a style={{
        color: "#00f",
        fontWeight: "900",
        fontSize: "1.2em"
      }} href={"https://www.youtube.com/watch?v=" + youtubeId} target="_blank">
        {title}<OpenInNewIcon/>
      </a>
      <iframe width="100%" height="300" src={"https://www.youtube.com/embed/" + youtubeId}
              allowFullScreen="false"></iframe>
    </div>
  </>;
  return (localStorage.getItem("showTutorials")==="true"?tag:"");
}
