import { useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export function ConfirmationDialog({
  open,
  abortLabel,
  onAbort,
  confirmLabel,
  onConfirm,
  title,
  description,
}) {
  const [confirming, setConfirming] = useState(false);

  async function onConfirmInternally() {
    setConfirming(true);
    await onConfirm();
    setConfirming(false);
  }

  return (
    <Dialog open={confirming || open} onClose={onAbort}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onAbort} disabled={confirming}>
          {abortLabel || "Cancel"}
        </Button>
        <Button onClick={onConfirmInternally} autoFocus disabled={confirming}>
          {confirming ? <CircularProgress /> : confirmLabel || "OK"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
