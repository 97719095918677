import React, { Component, useState } from "react";
import { fetchViaPost } from "../../helpers/fetchViaPost";
import '../../parts/wdl.css';
import { Button, Table, TableBody, TableHead, TableRow, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import * as PropTypes from "prop-types";
import { HtmlTooltip } from "../../styled-components/HtmlTooltip";


export class LeagueTable extends Component {

  getIcon() {
    var v = this.props;
    if (!v.visitorTeam.standings || v.visitorTeam.standings?.length === 0) {
      return <ExpandMoreIcon />
    } else {
      return <ExpandLessIcon />
    }
  }

  calc = async (v) => {
    if (v.visitorTeam.standings) {
      return;
    }
    v.localTeam.standings = await (await fetchViaPost(
      "get-standings",
      JSON.stringify({ season_id: v.seasonId, team_id: v.localteamId })
    )).json();

    v.visitorTeam.standings = await (await fetchViaPost(
      "get-standings",
      JSON.stringify({ season_id: v.seasonId, team_id: v.visitorteamId })
    )).json();

    this.drawWdlTable(v);
    this.forceUpdate();
  }

  drawWdlTable(v) {
    if (!v.visitorTeam.standings || v.visitorTeam.standings?.length === 0) {
      return "";
    }
    var t = [];
    Array.from(v.visitorTeam.standings).map((st) => {
      t.push(this.getHtmlTooltip(st))
    })
    t.push(<br />);
    Array.from(v.localTeam.standings).map((st) => {
      t.push(this.getHtmlTooltip(st))
    })
    return t;
  }


  getHtmlTooltip(st) {
    const states = { "L": "error", "D": "warning", "W": "success" };

    let value = st.fixture.scores;
    let score = this.prepareScore(value)
    let tootTip = <>
      <strong>{score.toString().replace("-", ":")}</strong>
      <hr />
      {st.fixture.localTeam.data.name + " - " + st.fixture.visitorTeam.data.name}
      <hr />
      {st.fixture.time.starting_at.date}
    </>;
    return <HtmlTooltip
      title={tootTip}>
      <Button variant="contained" size="small" color={states[st.form]} className="cwi">
        {st.form}
      </Button>
    </HtmlTooltip>;
  }

  prepareScore(value) {
    let htScore = value.ht_score;
    if (htScore === null) {
      return (<span>-</span>);
    }
    let ftScore = value.ft_score;
    let etScore = value.et_score;
    let psScore = value.ps_score;
    let firstScore = "-";
    if (ftScore === null) {
      firstScore = "- (" + htScore + ")";
    } else {
      firstScore = ftScore + " (" + htScore + ")";
    }
    if (etScore !== null) {
      firstScore += " (" + etScore + ")";
    }
    if (psScore !== null) {
      firstScore += " (" + psScore + ")";
    }
    return firstScore
  }


  render() {
    if (this.props.stats?.length === 0) {
      return null;
    }
    const stats = this.props.stats[this.props.localteamId];
    const statsV = this.props.stats[this.props.visitorteamId];
    return <Table>
      <TableHead>
        <th colSpan={2} align="left" style={{ width: "30%" }}>
          League table
        </th>
        <th align="left">SP</th>
        <th align="left">S</th>
        <th align="left">U</th>
        <th align="left">N</th>
        <th align="left">Goals</th>
        <th align="left">Diff</th>
        <th align="left">Points</th>
        <th align="left">
          WDL <Button variant="contained" style={{ backgroundColor: 'transparent' }} size="small" sx={{ color: 'black' }} className="cwi" onClick={() => this.calc(this.props)}>{this.getIcon()}</Button>
        </th>
      </TableHead>
      <TableBody>
        <TableRow>
          <th>{stats?.league.position}</th>
          <td>{this.props.localTeam.custom_name}</td>
          <td>{stats?.league.games}</td>
          <td>{stats?.league.won}</td>
          <td>{stats?.league.draw}</td>
          <td>{stats?.league.lost}</td>
          <td>{stats?.league.goals}</td>
          <td>{stats?.league.diff}</td>
          <td>{stats?.league.points}</td>
          <td rowSpan={2} colSpan={2}>{this.drawWdlTable(this.props)}</td>
        </TableRow>

        <TableRow>
          <th>{statsV?.league.position}</th>
          <td>{this.props.visitorTeam.custom_name}</td>

          <td>{statsV?.league.games}</td>
          <td>{statsV?.league.won}</td>
          <td>{statsV?.league.draw}</td>
          <td>{statsV?.league.lost}</td>
          <td>{statsV?.league.goals}</td>
          <td>{statsV?.league.diff}</td>
          <td>{statsV?.league.points}</td>
          <td></td>
        </TableRow>
        <TableRow>
          <th colSpan={2} align="left" style={{ width: "50%" }}>
            Home table
          </th>
          <th align="left">SP</th>
          <th align="left">S</th>
          <th align="left">U</th>
          <th align="left">N</th>
          <th align="left">Goals</th>
          <th align="left">Diff</th>
          <th align="left">Points</th>
        </TableRow>
        <TableRow>
          <th>{stats?.home.position}</th>
          <td>{this.props.localTeam.custom_name}</td>

          <td>{stats?.home.games}</td>
          <td>{stats?.home.won}</td>
          <td>{stats?.home.draw}</td>
          <td>{stats?.home.lost}</td>
          <td>{stats?.home.goals}</td>
          <td>{stats?.home.diff}</td>
          <td>{stats?.home.points}</td>
        </TableRow>
        <TableRow>
          <th colSpan={2} align="left" style={{ width: "50%" }}>
            Away table
          </th>
          <th align="left">SP</th>
          <th align="left">S</th>
          <th align="left">U</th>
          <th align="left">N</th>
          <th align="left">Goals</th>
          <th align="left">Diff</th>
          <th align="left">Points</th>
        </TableRow>
        <TableRow>
          <th>{statsV?.away.position}</th>
          <td>{this.props.visitorTeam.custom_name}</td>

          <td>{statsV?.away.games}</td>
          <td>{statsV?.away.won}</td>
          <td>{statsV?.away.draw}</td>
          <td>{statsV?.away.lost}</td>
          <td>{statsV?.away.goals}</td>
          <td>{statsV?.away.diff}</td>
          <td>{statsV?.away.points}</td>
        </TableRow>
      </TableBody>
    </Table>;
  }

}

LeagueTable.propTypes = {
  stats: PropTypes.arrayOf(PropTypes.any),
  seasonId: PropTypes.any,
  localteamId: PropTypes.any,
  localTeam: PropTypes.func,
  visitorteamId: PropTypes.any,
  visitorTeam: PropTypes.func
};
