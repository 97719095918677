export function sortAndRankTeams(rows) {
  return rows
    .map((e) => ({
      ...e,
      id: e.team_id,
      total_goals: Number(e.for_total) + Number(e.against_total),
    }))
    .sort((a, b) => b.total_strength - a.total_strength)
    .map((e, i) => ({ ...e, rank: i + 1 }));
}
