import { Button, Paper } from "@mui/material";
import { GridFooter } from "@mui/x-data-grid-pro";
import { useConfirm } from "material-ui-confirm";

export const FixturesFooter = ({
  selectionModel,
  mode,
  handleCompileConfirm,
  handleEvaluateConfirm,
}) => {
  const confirm = useConfirm();

  const handleConfirm = async () => {
    await confirm(
      mode === "compile"
        ? {
            title: `Compile ${selectionModel.length} matches?`,
            description:
              "Odds will be calculated and written to the database. Make sure your settings are fine.",
          }
        : {
            title: `Evaluate ${selectionModel.length} matches?`,
            description:
              "The evaluation of the matches induces an update of all parameters involved in the calculation for upcoming fixtures. Make sure you are happy with your settings.",
          }
    );
    if (mode === "compile") await handleCompileConfirm();
    else await handleEvaluateConfirm();
  };

  return (
    <div
      style={{
        position: "relative",
        height: "200%",
      }}
    >
      <Paper
        sx={{ px: 1 }}
        style={{
          position: "absolute",
          display: "flex",
          alignItems: "center",
          height: "50%",
          bottom: 0,
          width: "100%",
          background: "white",
        }}
      >
        <Button
          variant="contained"
          disabled={!selectionModel.length}
          onClick={handleConfirm}
        >
          {mode}
        </Button>
      </Paper>
      <GridFooter />
    </div>
  );
};
