export function fixInconsistencies(ranking) {
  return ranking.map((e) => {
    const for_total = (e.for_home + e.for_away) / 2;
    const against_total = (e.against_home + e.against_away) / 2;
    const for_homefactor = e.for_home / for_total;
    const against_homefactor = e.against_home / against_total;
    return {
      ...e,
      for_total,
      against_total,
      for_homefactor,
      against_homefactor,
    };
  });
}
